
import { Link } from "react-router-dom";
import Footer from "../../Componente/Footer";
import Header from "../../Componente/Header";


function Contact() {
    return (


        <>
            <Header />
            <div class="section">

                <div class="container">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="section-row">
                                <h3>Contact Information</h3>
                                <p>We are available for questions, suggestions, partnerships, special collaborations, feedback on our content, advertising inquiries, and any other information you may need. Reach out to us, and we’ll be happy to assist you!</p>
                                <ul class="list-style">
                                    <li><p><strong>Email:</strong> <a href="#">contact@pongpong79.com</a></p></li>
                                    <li><p><strong>Phone:</strong> +1 681 239 3332</p></li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-md-5 col-md-offset-1">
                            <div class="section-row">
                                <h3>Send A Message</h3>
                                <form>
                                    <div class="row">
                                        <div class="col-md-7">
                                            <div class="form-group">
                                                <span>Email</span>
                                                <input class="input" type="email" name="email" />
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">
                                                <span>Subject</span>
                                                <input class="input" type="text" name="subject" />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <textarea class="input" name="message" placeholder="Message"></textarea>
                                            </div>
                                            <button class="primary-button">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <Footer />
        </>

    );
}
export default Contact;