import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  // Estado para controlar o formulário de busca e os resultados
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isAsideActive, setIsAsideActive] = useState(false); // Controle do menu lateral

  // Função para abrir/fechar o campo de busca
  const handleSearchToggle = () => {
    setIsSearchActive(prevState => !prevState); // Alterna o estado de exibição do campo de busca
  };

  // Função para lidar com a pesquisa
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query === '') {
      setSearchResults([]);
      return;
    }

    // Exemplo de páginas para busca
    const allPages = [
      { title: 'News', url: '/category/news' },
      { title: 'Popular', url: '/category/popular' },
      { title: 'Web Design', url: '/category/web-design' },
      { title: 'JavaScript', url: '/category/javascript' },
      { title: 'CSS', url: '/category/css' },
      { title: 'Jquery', url: '/category/jquery' },
      { title: 'Home', url: '/' },
      { title: 'About Us', url: '/about' },
      { title: 'Contact', url: '/contact' },
    ];

    const filteredResults = allPages.filter(page => 
      page.title.toLowerCase().includes(query.toLowerCase())
    );

    setSearchResults(filteredResults);
  };

  // Função para abrir/fechar o menu lateral
  const handleAsideToggle = () => {
    setIsAsideActive(prevState => !prevState);
  };

  return (
    <header id="header">
      <div id="nav">
        <div id="nav-fixed">
          <div className="container">
            <div className="nav-logo">
              <Link to="/"><img src="/img/logo.png" alt="Logo" /></Link>
            </div>
            <ul className="nav-menu nav navbar-nav">
              <li><a href="category.html">News</a></li>
              <li><a href="category.html">Popular</a></li>
              <li className="cat-1"><a href="category.html">Web Design</a></li>
              <li className="cat-2"><a href="category.html">JavaScript</a></li>
              <li className="cat-3"><a href="category.html">CSS</a></li>
              <li className="cat-4"><a href="category.html">Jquery</a></li>
            </ul>
            <div className="nav-btns">
              {/* Botão para abrir o menu lateral */}
              <button className="aside-btn" onClick={handleAsideToggle}>
                <i className="fa fa-bars"></i>
              </button>
              {/* Botão de busca */}
              <button className="search-btn" onClick={handleSearchToggle}>
                <i className="fa fa-search"></i>
              </button>
              {/* Formulário de busca */}
              {isSearchActive && (
                <div className="search-container">
                  <input
                    className="search-input"
                    type="text"
                    name="search"
                    placeholder="Enter Your Search ..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <button className="search-close" onClick={handleSearchToggle}>
                    <i className="fa fa-times"></i>
                  </button>
                  {/* Exibindo resultados de busca */}
                  {searchResults.length > 0 && (
                    <ul className="search-results">
                      {searchResults.map((result, index) => (
                        <li key={index}>
                          <a href={result.url}>{result.title}</a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Menu lateral (Aside) */}
        {isAsideActive && (
          <div id="nav-aside" className="active">
            <div className="section-row">
              <ul className="nav-aside-menu">
              <li><Link to="/">Home</Link></li>
                <li><a href="about.html">About Us</a></li>
                <li><a href="#">Join Us</a></li>
                <li><a href="#">Advertisement</a></li>
                <li><Link to="/contact">Contacts</Link></li>
                
              </ul>
            </div>

            <div className="section-row">
              <h3>Recent Posts</h3>
              <div className="post post-widget">
                <a className="post-img" href="blog-post.html"><img src="./img/widget-2.jpg" alt=""/></a>
                <div className="post-body">
                  <h3 className="post-title"><a href="blog-post.html">Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically</a></h3>
                </div>
              </div>

              <div className="post post-widget">
                <a className="post-img" href="blog-post.html"><img src="./img/widget-3.jpg" alt=""/></a>
                <div className="post-body">
                  <h3 className="post-title"><a href="blog-post.html">Why Node.js Is The Coolest Kid On The Backend Development Block!</a></h3>
                </div>
              </div>

              <div className="post post-widget">
                <a className="post-img" href="blog-post.html"><img src="./img/widget-4.jpg" alt=""/></a>
                <div className="post-body">
                  <h3 className="post-title"><a href="blog-post.html">Tell-A-Tool: Guide To Web Design And Development Tools</a></h3>
                </div>
              </div>
            </div>
            
            <div className="section-row">
              <h3>Follow us</h3>
              <ul className="nav-aside-social">
                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
              </ul>
            </div>

            {/* Botão para fechar o menu lateral */}
            <button className="nav-aside-close" onClick={handleAsideToggle}>
              <i className="fa fa-times"></i>
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
