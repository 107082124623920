//import {BrowserRouter, Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from './view/Home';
import Blog from './view/Blog';
import Erro from './view/Erro';
import Contact from "./view/Contact";
import Post2 from "./blog-pages/Post2";

const Rotas = () => {
    return (

        // <BrowserRouter>
        //     <Switch>
        //         <Route exact path="/" component={Home} />
        //         <Route path="/blog" component={Blog} />
        //     </Switch>
        // </BrowserRouter>
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/blog" element={<Blog />} />
                <Route exact path="/*" element={<Erro />} />
                <Route exact path="/contact" element={<Contact/>} />
                <Route exact path="/best-places-to-travel-to-in-february" element={<Post2/>} />
            </Routes>
        </Router>

    )
}

export default Rotas;