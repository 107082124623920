import React, { useEffect, useState } from "react";

const PostList = () => {
  const [posts, setPosts] = useState([]);

  // Função simulando carregamento dos dados das páginas
  useEffect(() => {
    const fetchData = async () => {
      // Simule ou substitua por uma busca real de arquivos
      const fetchedPosts = [
        {
          imgSrc: "./img/post-1.jpg",
          category: "JavaScript",
          date: "December 03, 2024",
          title: "Testando se vai funcionar a busca por temas ",
          link: "blog-post.html",
        },
        {
          imgSrc: "./img/post-2.jpg",
          category: "Jquery",
          date: "March 27, 2018",
          title: "Ask HN: Does Anybody Still Use JQuery?",
          link: "blog-post.html",
        },
        // Adicione mais dados simulados ou conecte a uma API/arquivo
      ];

      setPosts(fetchedPosts);
    };

    fetchData();
  }, []);

  return (
    <>
      {posts.map((post, index) => (
        <div key={index} className="col-md-6">
          <div className="post post-thumb">
            <a className="post-img" href={post.link}>
              <img src={post.imgSrc} alt="" />
            </a>
            <div className="post-body">
              <div className="post-meta">
                <a className="post-category cat-2" href="category.html">
                  {post.category}
                </a>
                <span className="post-date">{post.date}</span>
              </div>
              <h3 className="post-title">
                <a href={post.link}>{post.title}</a>
              </h3>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PostList;
