import Footer from "../../Componente/Footer";
import Header from "../../Componente/Header";
import Comeco from "../../Componente/comeco";

function Home(){
    return(
       
       
        <>
        <Header/>
        <Comeco/>
        <Footer/>
        </>
        
    );
}
export default Home;