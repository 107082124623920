import React, { useState, useEffect } from 'react';

const Comments = () => {
  const [comments, setComments] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    website: '',
    message: '',
  });

  // Fetch de comentários ao carregar o componente
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch('https://pongpong79.com/comments.php');
        if (!response.ok) throw new Error('Failed to fetch comments');
        const data = await response.json();
        setComments(data);
      } catch (error) {
        console.error('Error fetching comments:', error);
        alert('Could not load comments. Please try again later.');
      }
    };

    fetchComments();
  }, []);

  // Atualiza os valores do formulário
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Envia o comentário
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://pongpong79.com/comments.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to save comment');
      }

      const result = await response.json();
      console.log('Comment saved successfully:', result);
      alert('Comment sent successfully!');
      setFormData({ name: '', email: '', website: '', message: '' });
      // Recarregar os comentários
      const updatedComments = await fetch('https://pongpong79.com/comments.php').then((res) => res.json());
      setComments(updatedComments);
    } catch (error) {
      console.error('Error sending comment:', error);
      alert(error.message || 'An unexpected error occurred.');
    }
  };

  return (
    <div>
      <h2>Comments</h2>
      <ul>
        {comments.map((comment) => (
          <li key={comment.id}>
            <h4>{comment.name}</h4>
            <p>{comment.message}</p>
            <small>{new Date(comment.created_at).toLocaleString()}</small>
          </li>
        ))}
      </ul>

      <h2>Leave a Reply</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="website"
          placeholder="Website"
          value={formData.website}
          onChange={handleChange}
        />
        <textarea
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Comments;
